/* Dashboard.css */
.dashboard {
  background-color: #F9FAFB;
}

.top-nav {
  padding: 20px 0;
}

.top-nav h2 {
  margin-bottom: 0;
}

.main-content {
  padding: 20px;
}

.balance-card {
  height: auto;
  margin-bottom: 20px;
  border: none;
  border-radius: 20px;
}

.balance-card .leftshift{
margin-left: 20px;
}


.slideable-content {
  overflow: hidden;
  transition: height 0.3s ease;
  height: 60px; /* Initial height */
}

.slideable-content:hover {
  height: auto; /* Expand on hover */
}

.menu-items {
  margin-top: 20px;
}

.menu-item {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  transition: transform 0.3s ease;
  margin: 10px;
  padding: 20px;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}







.menu-item2{
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 11px;
  transition: transform 0.3s ease;
  margin: 4px;
  padding: 10px;
  color: #fff;
background-color: skyblue;
margin-bottom: 30px;
}




.menu-item:hover {
  transform: translateY(-5px);
}

.menu-icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .menu-item {
    height: 150px;
  }
}
